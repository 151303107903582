import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export default class EditableInput extends React.Component {
  static propTypes = {
    text: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    isEditing: PropTypes.bool,
    inputTypeName: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    defaultInputValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onFocusOut: PropTypes.func,
    currencySymbol: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditing: this.props.isEditing || false,
      text: this.props.text || '',
    };

    this._handleClick = this._handleClick.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  _handleClick() {
    if (this.state.isEditing) {
      this.props.onFocusOut(this.state.text);
    } else {
      this.state.text;
    }

    this.setState({
      isEditing: !this.state.isEditing,
    });
  }

  _handleChange(value) {
    this.setState({
      text: value,
    });
  }

  render() {
    const { currencySymbol } = this.props;
    const labelText = this.state.text || this.props.defaultInputValue;

    if (this.state.isEditing) {
      return (
        <div>
          <NumberFormat
            defaultValue={labelText}
            isNumericString
            allowEmptyFormatting
            decimalScale={2}
            allowNegative={false}
            decimalSeparator="."
            className={this.props.inputClassName}
            ref={(input) => {
              this.textInput = input;
            }}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              this._handleChange(formattedValue);
            }}
            onBlur={this._handleClick}
            autoFocus
          />
        </div>
      );
    }

    return (
      <label
        className={this.props.labelClassName}
        onClick={this._handleClick}
      >
        {currencySymbol}
        {labelText}
      </label>
    );
  }
}
