import React, {useRef, useState, useLayoutEffect} from "react";

const ModalWindowWireframe = ({widthStyle, children}) => {

    const ref = useRef({});
    const [windowClasses, setWindowClasses] = useState('u-element-center u-fixed PaymentModal-width--35');

    useLayoutEffect(() => {
        if (ref.current.clientHeight > window.innerHeight) {
            setWindowClasses(`u-absolute u-element-top ${widthStyle}`);
        }
    }, [window.innerHeight, ref.current.clientHeight])

    return (
        <div ref={ref}
             className={`PaymentModal ${windowClasses}`}>
            {children}
        </div>
    )
}

export default ModalWindowWireframe
