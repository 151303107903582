import React from 'react';
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';

import { DebounceInput } from 'react-debounce-input';
import classNames from 'classnames';
import Spinner from '../shared/Spinner';

import { localFormatPhoneNumber } from '../../helpers/phoneHelper';

const DEBOUNCE_TIMEOUT = 1000;

export function PlayerEmail({
  email,
  onChange,
  isLoading = false,
  label = null,
  errors = {},
}) {
  return (
    <div className="row u-mb-2">
      <div className="col-12">
        <div className="default-form__input-group">
          {label && (
            <label
              htmlFor="player_email"
              className="default-form__label"
            >
              {label} <span className="u-color-red">*</span>
            </label>
          )}
          <DebounceInput
            type="email"
            id="player_email"
            required="required"
            className="browser-default default-form__input"
            onChange={(e) => onChange(e.target.value)}
            value={email}
            forceNotifyOnBlur
            debounceTimeout={DEBOUNCE_TIMEOUT}
          />
          {!!errors.email && (
            <div className="input-field__error">{errors.email}</div>
          )}
        </div>
      </div>
      <span className="u-relative">
        <Spinner
          isLoading={isLoading}
          position={{ top: -10, right: 0 }}
        />
      </span>
    </div>
  );
}

export function PlayerPhone({ value, onChange, error }) {
  return (
    <div className="row u-mb-2">
      <div className="col-6">
        <div className="default-form__input-group">
          <label
            htmlFor="player-phone"
            className="default-form__label"
          >
            Phone <span className="u-color-red">*</span>
          </label>
          <PhoneInput
            id="player_phone"
            name="player_phone"
            defaultCountry="AU"
            placeholder="Insert phone number"
            value={value}
            onChange={onChange}
            className={classNames({
              'invalid-number': !!value && !isValidPhoneNumber(value),
            })}
            displayInitialValueAsLocalNumber
            required
          />
          {!!error && (
            <div className="input-field__error">{error}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export function LastNameVerificator({ lastName, onChange }) {
  return (
    <React.Fragment>
      <div className="row u-mb-2">
        <div className="col-12">
          Provide the last name of the user to verify
        </div>
      </div>
      <div className="row u-mb-2">
        <div className="col-12">
          <div className="default-form__input-group">
            <DebounceInput
              id="player_lastname_verify"
              required="required"
              className="browser-default default-form__input"
              onChange={(e) => onChange(e.target.value)}
              value={lastName}
              forceNotifyOnBlur
              debounceTimeout={DEBOUNCE_TIMEOUT}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function PhoneVerificator({
  visiblePartNumber,
  verificationNumber,
  onChange,
}) {
  return (
    <React.Fragment>
      <div className="row u-mb-2">
        <div className="col-12">
          Enter the last 3 digits of the user phone number to verify
        </div>
      </div>
      <div className="row u-mb-2">
        <div className="col-12">
          <div className="default-form__input-group d-flex">
            <PhoneInput
              id="player_phone_part"
              name="player_phone_part"
              defaultCountry={
                parsePhoneNumber(`+${visiblePartNumber}000`)
                  .country || 'AU'
              }
              value={`+${visiblePartNumber}`}
              onChange={() => {}}
              displayInitialValueAsLocalNumber
              className="PlayerTeamForm-phone-input"
              numberInputProps={{
                style: {
                  border: 0,
                  color: '#8e8e8e',
                  fontSize: '14px',
                  fontWeight: 700,
                  width: '92px',
                },
              }}
              disabled
            />
            <input
              type="number"
              id="player_phone_verify"
              className="browser-default default-form__input PlayerTeamForm-input--digits"
              maxLength="3"
              onChange={(e) => onChange(e.target.value)}
              value={verificationNumber}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function PlayerInfo({
  playerInfo = {},
  message = 'User verified',
  isExistsWarning = false,
}) {
  const {
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
  } = playerInfo;
  const phoneText = phone
    ? `| ${localFormatPhoneNumber(phone)} `
    : '';

  const messageClass = classNames({
    'col-12': true,
    'PlayerTeamForm-success': !isExistsWarning,
    'PlayerTeamForm-warning': isExistsWarning,
  });

  return (
    <React.Fragment>
      <div className="row u-mb-2">
        <div className={messageClass}>{message}</div>
      </div>
      <div className="row u-mb-2">
        <div className="col-12">
          <div className="PlayerTeamForm-info PlayerTeamForm-info--player">
            {firstName} {lastName} {phoneText} | {email}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
