import chartBlackMarked from '../../assets/images/charts/charts_black_with_mark.svg';
import chartBlack from '../../assets/images/charts/charts_black.svg';
import chartGreenMarked from '../../assets/images/charts/charts_green_with_mark.svg';
import chartGreen from '../../assets/images/charts/charts_green.svg';
import chartYellowMarked from '../../assets/images/charts/charts_yellow_with_mark.svg';
import chartYellow from '../../assets/images/charts/charts_yellow.svg';
import chartRedMarked from '../../assets/images/charts/charts_red_with_mark.svg';
import chartRed from '../../assets/images/charts/charts_red.svg';

import noteBlackMarked from '../../assets/images/notes/notes_black_with_mark.svg';
import noteGreenMarked from '../../assets/images/notes/notes_green_with_mark.svg';
import noteLightGreenMarked from '../../assets/images/notes/notes_light_green_with_mark.svg';
import noteLightGreen from '../../assets/images/notes/notes_light_green.svg';
import noteYellowMarked from '../../assets/images/notes/notes_yellow_with_mark.svg';
import noteOrangeMarked from '../../assets/images/notes/notes_orange_with_mark.svg';
import noteOrange from '../../assets/images/notes/notes_orange.svg';
import noteRedMarked from '../../assets/images/notes/notes_red_with_mark.svg';

const icons = {
  chartBlackMarked,
  chartBlack,
  chartGreenMarked,
  chartGreen,
  chartYellowMarked,
  chartYellow,
  chartRedMarked,
  chartRed,

  noteBlackMarked,
  noteGreenMarked,
  noteLightGreenMarked,
  noteLightGreen,
  noteYellowMarked,
  noteOrangeMarked,
  noteOrange,
  noteRedMarked,
};

export default icons;
