import { useState, useEffect, useReducer, useRef } from 'react';
import axios from 'axios';
import { toast } from '../lib/utils';

const ActionTypes = {
  FETCH_INIT: 'fetch_init',
  FETCH_SUCCESS: 'fetch_success',
  FETCH_FAILURE: 'fetch_failure',
};

const showNotification = ({ enabled, type, message }) => {
  if (enabled) {
    toast(message, type);
  }
};

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ActionTypes.FETCH_SUCCESS:
      showNotification({
        type: 'success',
        ...action.notification,
      });
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case ActionTypes.FETCH_FAILURE:
      showNotification({
        type: 'fail',
        ...action.notification,
      });
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
};

const useDataApi = (
  initialData,
  initialConfig,
  initialOtherProps = {
    withSuccessNotification: false,
    successMessage: '',
    withErrorNotification: true,
    errorMessage: '',
  },
) => {
  const [config, setConfig] = useState(initialConfig);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
  });

  const otherProps = useRef({
    errorNotification: {
      message:
        initialOtherProps.errorMessage ||
        'Something went wrong. Please try again.',
      enabled: initialOtherProps.withErrorNotification,
    },
    successNotification: {
      message:
        initialOtherProps.successMessage || 'Sucessfully got data',
      enabled: initialOtherProps.withSuccessNotification,
    },
    ...initialOtherProps,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      if (!config || !config.url) return;

      dispatch({ type: ActionTypes.FETCH_INIT });

      try {
        const result = await axios(config);
        if (!didCancel) {
          dispatch({
            type: ActionTypes.FETCH_SUCCESS,
            notification: otherProps.current.successNotification,
            payload: result.data.data,
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        if (!didCancel) {
          dispatch({
            type: ActionTypes.FETCH_FAILURE,
            notification: otherProps.current.errorNotification,
          });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [config, otherProps]);

  return [state, setConfig];
};

export default useDataApi;
