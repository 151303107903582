import React from 'react';
import Button from '../shared/Button';
import Modal from '../shared/Modal';

export const ConfirmDeleteModal = ({
  active,
  entity,
  onConfirm,
  onCancel,
}) => (
  <Modal show={active} id={`delete-${entity}-modal`}>
    <div className="center-align">
      <h5 className="u-weight-500 u-pb-2">Delete {entity}</h5>
      <p className="u-pb-3">
        Do you really want to delete this {entity}?
      </p>
      <div className="d-flex justify-content-center">
        <Button
          id={`confirm-delete-${entity}-btn`}
          mod="Button--redBg u-mr-1"
          onClick={onConfirm}
          title="Delete"
        />
        <Button
          id={`cancel-delete-${entity}-btn`}
          mod="Button--cancel"
          onClick={onCancel}
          title="Cancel"
        />
      </div>
    </div>
  </Modal>
);
