import React from 'react';
import ReactTooltip from 'react-tooltip';

export default class LinkWithTooltip extends React.Component {
  render() {
    const {
      url,
      b_text,
      error_text,
      link_class,
      isDisabled,
    } = this.props;

    return (
      <React.Fragment>
        {isDisabled && (
          <ReactTooltip id="tooltipCannotClickLink" effect="solid">
            <span>{error_text}</span>
          </ReactTooltip>
        )}
        <a
          data-tip
          data-for="tooltipCannotClickLink"
          href={isDisabled ? '#' : url}
          className={
            link_class + (isDisabled ? ' Button--disabled' : '')
          }
        >
          {b_text}
        </a>
      </React.Fragment>
    );
  }
}
