import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import {ConfirmTerminateModal} from "./ConfirmTerminateModal";
import {buildQueryParams} from "../Payments/util/_build_request_params"

export default function RefundActions({refundId, stop, updatePaymentsList}) {

    const [terminateRefundActive, setTerminateRefundActive] = useState(false)
    const [isStopRefund, setStopRefund] = useState(stop);
    
    const params = {
        refund_id: refundId
    };

    const handlePlayRefund = () => {
        axios
        .delete(`/api/pause_refund?${buildQueryParams(params)}`);
        setStopRefund(false)
    };

    const handleStopRefund = () => {
        axios
        .patch(`/api/pause_refund?${buildQueryParams(params)}`)
        .then(() => {
            setStopRefund(true)
        })
    };

    const handleTerminateRefund = () => {
        setTerminateRefundActive(true)
    };

    const handleConfirmTerminateRefund = () => {
        axios
        .delete(`api/refunds/${refundId}`)
        .then(() => {
            setTerminateRefundActive(false)
            updatePaymentsList()
        })
    };

    const handleCancelTerminateRefund = () => {
        setTerminateRefundActive(false)
    };

    return(
        <div>
            {isStopRefund ? 
                <i className="far fa-play-circle u-font-size-20  RefundActions-icon u-pointer" onClick={handlePlayRefund}></i>
                : <i className="far fa-pause-circle u-font-size-20  RefundActions-icon u-pointer" onClick={handleStopRefund}></i>
            }
            <i className="far fa-times-circle u-font-size-20  RefundActions-icon RefundActions-icon-terminate u-pointer" onClick={handleTerminateRefund}></i>
            <ConfirmTerminateModal
                entity="refund"
                active={terminateRefundActive}
                onConfirm={handleConfirmTerminateRefund}
                onCancel={handleCancelTerminateRefund}
            />
        </div>
    )
}

RefundActions.propTypes = {
    refundId: PropTypes.number.isRequired,
    stop: PropTypes.bool.isRequired
}
