import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import EditableInput from './EditableInput';
import { leagueEnrolmentDTO } from '../../lib/DTO';
import { update } from '../../lib/API';

export default class Team extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    captain: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    leagueId: PropTypes.number.isRequired,
    league_fee: PropTypes.string,
    team_fee: PropTypes.string,
    team_title: PropTypes.string.isRequired,
    team_url: PropTypes.string.isRequired,
  };

  state = {
    team_fee: this.props.team_fee,
    errors: null,
  };

  saveTeamFee = (newFee) => {
    const { id, leagueId } = this.props;

    update(
      { id, fee: newFee },
      `/leagues/${leagueId}/team_fees/${id}`,
      (response) => {
        this.setState({
          team_fee: response.fee,
        });
      },
      (errors) => this.setState({ errors }),
    );
  };

  feeDisplayingFormat() {
    const { league_fee } = this.props;
    const { team_fee } = this.state;
    const price = team_fee || league_fee;

    return parseInt(price) === parseFloat(price)
      ? parseInt(price)
      : parseFloat(price).toFixed(2);
  }

  labelClassNameHandler() {
    const { league_fee } = this.props;
    const { team_fee } = this.state;

    if (!!team_fee && parseFloat(team_fee) < parseFloat(league_fee)) {
      return 'team-item__fee team-item__fee_individual';
    }

    return 'team-item__fee';
  }

  render() {
    const {
      team_title,
      captain,
      team_url,
      currencySymbol,
    } = this.props;
    const inputText = this.feeDisplayingFormat();
    const labelColor = this.labelClassNameHandler();

    return (
      <li className="team-item">
        <div className="team-item__title">
          <a href={team_url}>{team_title}</a>
          <EditableInput
            text={inputText}
            inputTypeName="number"
            defaultInputValue={inputText}
            labelClassName={labelColor}
            inputClassName="text-input__inline-editable"
            onFocusOut={this.saveTeamFee}
            currencySymbol={currencySymbol}
          />
        </div>
        <div>({captain})</div>
      </li>
    );
  }
}
