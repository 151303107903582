import axios from 'axios';
import { handleResponseErrorFormat, toast } from '../lib/utils';

export function addPlayerRoleWithPhone(
  playerId,
  phone,
  onSuccess,
  onFail,
) {
  return axios
    .put(`/api/players/${playerId}`, { phone })
    .then((response) => {
      axios.put(`/api/roles/players/${playerId}`).then(() => {
        toast('Player successfully added', 'success');
      });
      onSuccess(response.data);
    })
    .catch((error) => {
      onFail(handleResponseErrorFormat(error));
    });
}

export function addPlayerRole(playerId, onSuccess, onFail) {
  return axios
    .put(`/api/roles/players/${playerId}`)
    .then((response) => {
      toast('Player successfully added', 'success');
      onSuccess(response.data);
    })
    .catch((error) => {
      onFail(handleResponseErrorFormat(error));
    });
}

export function addPlayer(data, onSuccess, onFail) {
  return axios
    .post('/api/players.json', data)
    .then((response) => {
      toast('Player successfully added', 'success');
      onSuccess(response.data);
    })
    .catch((error) => {
      onFail(handleResponseErrorFormat(error));
    });
}
