import axios from 'axios'

const patchPaymentApi = async (url) => {
    return axios.patch(url, {})
        .then((response) => response.status)
        .catch((err) => {
            if (err && err.response && err.response.status) {
                return err.response.status
            }
            return null;
        })
}

const putPaymentApi = async (url) => {
    return axios.put(url, {})
        .then(response => response)
        .catch(err => err.response)
}

const getPaymentsWithFilters = async (path) => {
    return axios({
        method: 'get',
        url: `/api${path}`
    });
}

const addPaymentItem = async (path, data) => {
    return axios({
        method: 'post',
        url: `/api${path}`,
        data,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    })
        .then(response => response)
        .catch(err => err.response)
}

export {
    patchPaymentApi,
    putPaymentApi,
    getPaymentsWithFilters,
    addPaymentItem
}
