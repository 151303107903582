import React from 'react';

export const SVG = {
  arrowLeft: () => (
    <svg
      width="10px"
      height="15px"
      viewBox="0 0 10 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon_left" strokeWidth="2" stroke="#49c29c">
          <polyline
            id="Path"
            transform="translate(5.250000, 7.500000) rotate(90.000000) translate(-5.250000, -7.500000) "
            points="-1.25 4.25 5.25 11.25 11.75 4.25"
          />
        </g>
      </g>
    </svg>
  ),

  arrowRight: () => (
    <svg
      width="10px"
      height="15px"
      viewBox="0 0 10 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon_right" strokeWidth="2" stroke="#49c29c">
          <polyline
            id="Path"
            transform="translate(4.250000, 7.500000) scale(-1, 1) rotate(90.000000) translate(-4.250000, -7.500000) "
            points="-2.25 4.25 4.25 11.25 10.75 4.25"
          />
        </g>
      </g>
    </svg>
  ),

  calendar: () => (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon_calendar" fill="#B9C5DC">
          <path
            d="M12,4.00010618 C12,3.44751204 11.5536934,3 11.0049107,3 C11,3.55237094 10.5561352,4 10,4 C9.44771525,4 9,3.55628335 9,3.00019251 L5,3 C5,3.55237094 4.55613518,4 4,4 C3.44771525,4 3,3.55628335 3,3.00019251 C2.44572825,3 2,3.44694334 2,4.00010618 L2,5 L12,5 L12,4.00010618 Z M2,7 L2,11 L2,11 C2,11.5522847 2.44771525,12 3,12 L11,12 L11,12 C11.5522847,12 12,11.5522847 12,11 L12,7 L2,7 Z M14,4.00010618 L14,10.9998938 C14,12.6564091 12.6600619,14 11.0049107,14 L2.99508929,14 C1.34052774,14 0,12.6558517 0,10.9998938 L0,4.00010618 C0,2.34359085 1.33993808,1 2.99508929,1 C3,0.447629061 3.44386482,0 4,0 C4.55228475,0 5,0.443716645 5,0.999807492 L9,1 C9,0.447629061 9.44386482,0 10,0 C10.5522847,0 11,0.443716645 11,0.999807492 C12.6594723,1 14,2.34414832 14,4.00010618 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  ),

  arrowRound: (props) => (
    <svg
      viewBox="0 0 474.64 474.64"
      width="20px"
      height="20px"
      {...props}
    >
      <g fill={props.color || '#1194F6'}>
        <path d="M237.32.016C106.249.016 0 106.233 0 237.336s106.281 237.288 237.32 237.288 237.32-106.249 237.32-237.32S368.391.016 237.32.016zm0 442.825c-113.336 0-205.537-92.169-205.537-205.569s92.2-205.537 205.537-205.537 205.537 92.233 205.537 205.537-92.169 205.569-205.537 205.569z" />
        <path d="M247.554 161.534c-3.115-3.115-7.183-4.608-11.283-4.608s-8.168 1.494-11.283 4.608l-103.07 103.071c-6.198 6.198-6.198 16.273 0 22.47 6.198 6.198 16.273 6.198 22.47 0l91.883-91.883 91.883 91.883c6.198 6.198 16.273 6.198 22.47 0 6.198-6.198 6.198-16.273 0-22.47l-103.07-103.071z" />
      </g>
    </svg>
  ),

  crossRound: (props) => {
    const { noCircle, ...rest } = props;
    return (
      <svg
        viewBox="0 0 455.992 455.992"
        width="20px"
        height="20px"
        {...rest}
      >
        <g fill={props.color || 'currentColor'}>
          {!noCircle && (
            <path d="M227.996 0C102.081 0 0 102.081 0 227.996c0 125.945 102.081 227.996 227.996 227.996 125.945 0 227.996-102.051 227.996-227.996C455.992 102.081 353.941 0 227.996 0zm0 425.593c-108.952 0-197.597-88.645-197.597-197.597S119.044 30.399 227.996 30.399s197.597 88.645 197.597 197.597-88.645 197.597-197.597 197.597z" />
          )}
          <path d="M312.142 122.358l-83.538 83.568-74.965-83.568c-5.928-5.928-15.565-5.928-21.492 0-5.928 5.928-5.928 15.565 0 21.492l74.965 83.568-84.723 84.723c-5.928 5.928-5.928 15.595 0 21.492 5.928 5.928 15.565 5.928 21.492 0l83.568-83.538 74.965 83.538c5.897 5.928 15.565 5.928 21.462 0 5.928-5.898 5.928-15.565 0-21.492l-74.995-83.538 84.723-84.754c5.928-5.928 5.928-15.565 0-21.492-5.928-5.927-15.534-5.927-21.462.001z" />
        </g>
      </svg>
    );
  },
};
