import PhoneNumberValidator from 'awesome-phonenumber';

export {
  ucFirst,
  getListDataFromObject,
  getErrorTextFromObject,
  componentsJoin,
  isPhoneValid,
};

function ucFirst(s) {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
}

function getListDataFromObject(obj) {
  return Object.entries(obj).map((item) => ({
    id: item[0],
    title: item[1],
  }));
}

function getErrorTextFromObject(errorsObj) {
  if (
    !errorsObj
    || !errorsObj.response
    || typeof errorsObj.response.data !== 'object'
  )
    return 'undefined error';

  const errors = Object.entries(errorsObj.response.data).map(
    (item) => {
      const prefix = item[0] === 'base' ? '' : item[0];
      let msg = `${prefix} ${item[1]}`.trim().replace(/_/g, ' ');
      msg = ` - ${ucFirst(msg)}`;

      return msg;
    },
  );

  return errors.join(' <br>');
}

function componentsJoin(arr, sep) {
  if (arr.length === 0) {
    return [];
  }

  return arr
    .slice(1)
    .reduce((xs, x, i) => xs.concat([sep, x]), [arr[0]]);
}

function isPhoneValid(value, countryCode) {
  const pn = new PhoneNumberValidator(value, countryCode);
  return pn.isValid();
}
