import React from 'react';

export const Row = ({ children, hide, classMod = '' }) => (
  <div
    className={`row ${classMod}`}
    style={{ display: hide ? 'none' : 'flex' }}
  >
    {children}
  </div>
);

export const Col = ({ l, offset, children, align, mod = '' }) => (
  <div
    className={`
    ${l ? `col-${l}` : ''}
    ${offset ? `offset-${offset}` : ''}
    ${mod}`}
    style={align ? { textAlign: align } : null}
  >
    {children}
  </div>
);
