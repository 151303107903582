import React from "react";
import PropTypes from 'prop-types';
import "react-dates/initialize";
import {
  DayPickerSingleDateController,
  isSameDay,
  isInclusivelyBeforeDay
} from "react-dates";
import moment from "moment";
import { SVG } from './SVG';

const TODAY = moment();


export default class Calendar extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    blockedBefore: PropTypes.object,
    multiSelect: PropTypes.bool,
    mod: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  };

  state = {
    startDate: moment(),
    endDate: moment(),
    focusedInput: null,
    focused: null,
    date: TODAY
  };

  isBlocked(day) {
    const { blockedBefore } = this.props;

    return isInclusivelyBeforeDay(day, blockedBefore);
  }

  isToday(day) {
    return isSameDay(day, TODAY);
  }

  isSelected(day) {
    const { value, multiSelect } = this.props;

    return multiSelect
      ? value.some((d) => isSameDay(day, d))
      : isSameDay(day, value);
  }

  selectDay(day) {
    const { onSelect, multiSelect, value } = this.props;
    this.setState({ date: day });

    if (multiSelect) {
      if (this.isSelected(day)) {
        onSelect(value.filter((d) => !isSameDay(d, day)));
      } else {
        onSelect([...value, day]);
      }
    } else {
      onSelect(day);
    }
  }

  render() {
    const { mod = 'calendar-single-select' } = this.props;
    const { focused, date } = this.state;

    return (
      <div className={mod}>
        <DayPickerSingleDateController
          onDateChange={(day) => this.selectDay(day)}
          focused={focused}
          isDayBlocked={(day) => this.isBlocked(day)}
          isDayHighlighted={(day) => this.isSelected(day)}
          date={date}
          enableOutsideDays
          numberOfMonths={1}
          navPrev={<SVG.arrowLeft />}
          navNext={<SVG.arrowRight />}
        />
      </div>
    );
  }
}

