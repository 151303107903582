const errorMessage = 'An error occurred!';
const deleteError = "Faild to delete"
const invoiceNotFound = 'Invoice wasn\'t found! Please, refresh the tab';
const invoiceWrongStatus = 'you try to update the invoice with wrong status';
const amountAdjustmentStatus = 'amount should be positive';
const refundFaild = 'Failed to refund';
const payfeeFaild = 'Failed to pay fee';

export {
    errorMessage, 
    deleteError,
    invoiceNotFound,
    invoiceWrongStatus,
    amountAdjustmentStatus,
    refundFaild,
    payfeeFaild
}