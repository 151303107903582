import moment from 'moment-timezone';
import { TZ } from '../components/shared/configs';
import { findById } from './utils';

const CELL_HEIGHT = 40;

export const buildHoursList = () => getArrayWithAmount(24).map((el, index) => {
    const suffix = index < 12 ? 'AM' : 'PM';
    const hour = index % 12 === 0 ? 12 : index % 12;
    return `${hour} ${suffix}`;
  });

export const getArrayWithAmount = (amount) => new Array(amount).fill(1);

export const HOURS_RANGE = buildHoursList().slice(7, 24);
export const MINUTES_RANGE = getArrayWithAmount(60).map((_, i) => i);
export const MAX_VISIBLE_SLOTS = 4;

export const startHour = ({ first_content }) => moment(first_content, 'hh:mm A').format('h A');

export const nextHourOfSlot = ({ first_content }) => moment(first_content, 'hh:mm A').add(1, 'hour').format('h A');

export const nextHour = (str) => moment(str, 'hh:mm A').add(1, 'hour').format('h A');

export const prevHour = (str) => moment(str, 'h A').subtract(1, 'hour').format('h A');

export const startMinutes = ({ first_content }) => moment(first_content, 'hh:mm A').minutes();

export const selectedTimeSlots = (timeslotsData) => {
  if (!timeslotsData) {
    return [];
  }
  const result = [];

  Object.keys(timeslotsData).forEach((court) => Object.keys(timeslotsData[court]).forEach((hours) => {
      const slot = timeslotsData[court][hours];

      if (slot.id || !!slot.disabled) {
        result.push(slot);
      }
    }),
  );
  return result;
};

export const selectedCount = (timeslotsData) => timeslotsData ? selectedTimeSlots(timeslotsData).length : 0;

export const getTimeSlotOffset = (slot) => (CELL_HEIGHT / 60) * startMinutes(slot);

const prepareData = (table, team, time) => {
  if (!time || !team) {
    return;
  }
  if (!table[team]) {
    table[team] = {};
  }
  table[team][time] = (table[team][time] || 0) + 1;
};

export const timeslotPreviewData = (rounds, teams) => {
  const table = {};
  const matches = [];
  let times = {};

  Object.keys(rounds)
    .map((key) => rounds[key])
    .forEach((m) => {
      matches.push(...m);
    });

  matches.forEach(({ start_time, team_a_id, team_b_id }) => {
    if (start_time && team_a_id && team_b_id) {
      const time = moment(start_time).format('hh:mm');
      times[time] = true;

      prepareData(table, findById(teams, team_a_id, {}).title, time);
      prepareData(table, findById(teams, team_b_id, {}).title, time);
    }
    if (!team_a_id) {
      prepareData(table, findById(teams, team_b_id, {}).title, 'BYE');
    }
    if (!team_b_id) {
      prepareData(table, findById(teams, team_a_id, {}).title, 'BYE');
    }
  });

  times = Object.keys(times).sort();
  times.unshift('BYE');
  return { table, times };
};

export const defaultStartTime = () => {
  const today = moment().set({ hour: 12, minute: 0 }).format();
  return today;
};
