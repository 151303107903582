import axios from 'axios';
import { redirect, toast } from '../../../lib/utils'; 

const handleCloseListing = (id) => {
    axios
    .delete(`/api/registration_listings/${id}`)
    .then(response => {
      redirect('/registrations')
    })
    .catch(error => {
      const { status: errStatus, data: errData } = error.response;
      if (errStatus === 422) {
        toast(errData.message, 'fail', "Can't remove because still team registrations existing")
      } else if (errStatus === 404) {
        console.error('id not found')
      } else console.error(error)
    })
};

const getRegistrations = (id, setNewTeamRegistrations, setTeamListRegistrations, setWaitingListRegistrations) => {
    axios
    .get(`/api/registration_listings/${id}/team_registrations`)
    .then(({data: { data: data }}) => {
      setNewTeamRegistrations(data.fresh_team_registrations)
      setTeamListRegistrations(data.processed_team_registrations)
      setWaitingListRegistrations(data.waiting_team_registrations)
    })
};

const addTeamToListing = (id, params, disablePopup) => {
    axios
      .post(`/api/registration_listings/${id}/team_registrations`, params)
      .then(response => {
        disablePopup()
      })
      .catch(error => console.error(error))
};

const updateTeamInformation = (id, params, teamID, disablePopup) => {
    axios
    .patch(`/api/registration_listings/${id}/team_registrations/${teamID}`, params)
    .then(response => {
      disablePopup()
    })
    .catch(error => console.error(error))
};

const transferToListingRequest = (id, params) => {
    axios
      .post(`/api/registration_listings/${id}/transfer_into_listing?${params}`)
      .then(response => {
        redirect('/registrations')
      })
      .catch(error => {
        const { status: errStatus, data: errData } = error.response;
        if (errStatus === 404) {
          console.error(errData.message)
        } else console.error(error)
      })
};

const transferToAllTeams = (listing_id, registration_id, setNewTeamRegistrations, setAllTeamRegistrations, setWaitingListRegistrations) => {
    axios
    .patch(`/api/registration_listings/${listing_id}/process_registrations/${registration_id}`)
    .then(response => {
      getRegistrations(listing_id, setNewTeamRegistrations, setAllTeamRegistrations, setWaitingListRegistrations)
    })
    .catch(error => console.error(error))
};

const deleteTeam = (listing_id, registration_id, setNewTeamRegistrations, setAllTeamRegistrations, setWaitingListRegistrations) => {
    axios
    .delete(`/api/registration_listings/${listing_id}/team_registrations/${registration_id}`)
    .then(response => {
      getRegistrations(listing_id, setNewTeamRegistrations, setAllTeamRegistrations, setWaitingListRegistrations)
    })
    .catch(error => console.error(error))
};

const proccessWaitingTeamRegistration = (listing_id, registration_id, setNewTeamRegistrations, setAllTeamRegistrations, setWaitingListRegistrations) => {
  axios
  .patch(`/api/registration_listings/${listing_id}/process_waitings/${registration_id}`)
  .then(response => {
    getRegistrations(listing_id, setNewTeamRegistrations, setAllTeamRegistrations, setWaitingListRegistrations)
  })
  .catch((error) => {
    const { status: errStatus, data: errData } = error.response;
    if (errStatus === 422) {
      toast(errData.message, 'fail', "Capacity exceeded")
    }
    else if (errStatus === 404) {
      toast(errData.message, 'fail', "not found")
    }
    else console.error(error)
  })
}

export {
    handleCloseListing,
    getRegistrations,
    addTeamToListing,
    updateTeamInformation,
    transferToListingRequest,
    transferToAllTeams,
    deleteTeam,
    proccessWaitingTeamRegistration
}
