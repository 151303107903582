
const tabs = [
    {tab: 'Transfers', api: '/transfers', className: 'Button--switchFirst'},
    {tab: 'Refunds', api: '/refunds', className: 'Button--switchMiddle'},
    {tab: 'User Refunds', api: '/user_refunds', className: 'Button--switchMiddle'},
    {tab: 'Breakdown', api: '/break_downs', className: 'Button--switchLast'}
]

export {
    tabs
}
