import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { DebounceInput } from 'react-debounce-input';
import { Col, Row } from '../shared/Grid';

const TextAreaInput = (props) => {
  const taStyle = {
    overflowY: 'scroll',
    height: 150,
    resize: 'none',
  };

  return <textarea style={taStyle} {...props} />;
};

export default class ManagerNotesEditor extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    venue_id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
  };

  static defaultProps = {
    message: '',
  };

  state = {
    message: this.props.message,
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ message: value }, () => this.saveChanges());
  };

  saveChanges = () => {
    const { venue_id, date } = this.props;
    const { message } = this.state;
    const type = 'ManagerNote';

    return axios
      .post(`/venues/${venue_id}/notes.json`, {
        venue_note: { message, date, type },
      })
      .then((res) => {
        M.toast({
          html: 'Notes has been updated',
          classes: 'u-bg-emerald',
        });
      })
      .catch((error) => {
        M.toast({
          html: 'Failed to update notes',
          classes: 'u-bg-red',
        });
      });
  };

  render() {
    const { message } = this.state;

    return (
      <Row classMod="u-mb-2">
        <Col l="12">
          <DebounceInput
            value={message}
            minLength={2}
            debounceTimeout={1500}
            element={TextAreaInput}
            className="schedule__notes"
            onChange={this.handleChange}
          />
        </Col>
      </Row>
    );
  }
}
