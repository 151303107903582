import axios from 'axios'

const getJobs = async (path) => {
    return axios({
        method: 'get',
        url: `/api${path}`
    });
}

export {
    getJobs
}
