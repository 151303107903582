import PropTypes from 'prop-types';
import React from 'react';

export default class Search extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  onChange({ target: { value } }) {
    const { onChange } = this.props;

    this.setState({ value });
    onChange(value.toLowerCase().trim());
  }

  setFocus() {
    if (this.filterInput) {
      setTimeout(() => this.filterInput.focus(), 100);
    }
  }

  render() {
    return (
      <div
        className="search-wrapper"
        onMouseOver={() => this.setFocus()}
      >
        <i className="far fa-search filter-search-icon" />
        <input
          className="search-field browser-default"
          autoFocus
          ref={(el) => (this.filterInput = el)}
          onChange={(event) => this.onChange(event)}
          type="text"
        />
      </div>
    );
  }
}
