import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactQuill from 'react-quill';
import classnames from 'classnames';
import { Col, Row } from '../shared/Grid';
import Button from '../shared/Button';

class NotesRichEditor extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    venue_id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
  };

  static defaultProps = {
    message: '',
  };

  state = {
    message: this.props.message,
    isReadOnly: true,
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'code-block',
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
    ],
  };

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'font',
    'align',
  ];

  toggleReadOnly = () => {
    this.setState({ isReadOnly: !this.state.isReadOnly });
  };

  handleChange = (value) => {
    this.setState({ message: value });
  };

  handleSave = () => {
    const { venue_id, date } = this.props;
    const { message } = this.state;
    const type = 'AdminNote';

    return axios
      .post(`/venues/${venue_id}/notes.json`, {
        venue_note: { message, date, type },
      })
      .then((res) => {
        M.toast({
          html: 'Notes has been updated',
          classes: 'u-bg-emerald',
        });
        this.toggleReadOnly();
      })
      .catch((error) => {
        M.toast({
          html: 'Failed to update notes',
          classes: 'u-bg-red',
        });
      });
  };

  render() {
    const { message, isReadOnly } = this.state;

    const notesEditorClass = classnames({
      'admin-notes': true,
      'read-only': isReadOnly,
    });
    const editBtnClass = classnames({
      'Button Button--medium Button--primary': true,
      'Button--disabled': !isReadOnly,
    });
    const saveBtnClass = classnames({
      'Button Button--medium Button--primary u-mr-1': true,
      'Button--disabled': isReadOnly,
    });

    return (
      <React.Fragment>
        <Row classMod="u-mb-2">
          <Col l="12" mod={notesEditorClass}>
            <ReactQuill
              theme="snow"
              modules={this.modules}
              formats={this.formats}
              value={message}
              onChange={this.handleChange}
              readOnly={isReadOnly}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
          </Col>
        </Row>

        <Row classMod="u-mb-2">
          <Col l="12">
            <div className="d-flex justify-content-flex-end">
              <Button
                mod={saveBtnClass}
                title="Save"
                onClick={this.handleSave}
                disabled={isReadOnly}
                id="notes-rich-editor-save"
              />
              <Button
                mod={editBtnClass}
                title="Edit"
                onClick={this.toggleReadOnly}
                disabled={!isReadOnly}
                id="notes-rich-editor-edit"
              />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default NotesRichEditor;
