import PropTypes from 'prop-types';
import React from 'react';
import { updateInputs } from '../../lib/materialHelper';

export default class Step extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    completed: PropTypes.func,
    closed: PropTypes.bool,
    title: PropTypes.string.isRequired,
    autoCollapse: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  state = { active: this.status === 'empty' };

  get status() {
    const { disabled, value } = this.props;
    return disabled
      ? 'disabled'
      : this.isFilled(value)
      ? 'filled'
      : 'empty';
  }

  isFilled(value) {
    const { completed } = this.props;
    return !completed
      ? value !== null && value !== undefined
      : completed(value);
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { autoCollapse, disabled } = this.props;

    if (!next.disabled && next.disabled !== disabled) {
      this.setState({ active: true });
    }
    if (autoCollapse && this.isFilled(next.value)) {
      this.setState({ active: false });
    }
  }

  toggle() {
    const { active } = this.state;
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({ active: !active }, () => updateInputs());
    }
  }

  render() {
    const {
      disabled,
      number,
      title,
      value,
      children,
      closed,
    } = this.props;
    const { active } = this.state;
    const { status } = this;

    return (
      <div className="step">
        <div
          className={`step__header step__header_${status}${
            active ? '-active' : ''
          }${closed ? ' step__header_closed' : ''}`}
          onClick={() => this.toggle()}
        >
          <div className={`step__icon step__icon_${status}`}>
            {number}
          </div>
          <div className="step__title">{title}</div>
          <div className="step__value">{value}</div>
        </div>
        <div
          className={`step__content${
            !active || disabled || closed
              ? ' step__content_hidden'
              : ''
          }`}
        >
          {children}
        </div>
      </div>
    );
  }
}
