import React, { useState } from 'react';
import axios from 'axios';
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

export default function Form({
  player,
  errors,
  onSubmit,
  isDisabled,
  tooltipMessage,
}) {
  const [firstName, setFirstName] = useState(player.first_name);
  const [lastName, setLastName] = useState(player.last_name);
  const [email, setEmail] = useState(player.email);
  const [phone, setPhone] = useState(`+${player.phone}`);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const handleOnChange = (value) => {
    const isNumberValid = isValidPhoneNumber(value);

    setPhone(value);
    setIsPhoneValid(isNumberValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(firstName, lastName, email, phone);
  };

  return (
    <form onSubmit={handleSubmit} className="PlayerTeamForm">
      <div className="row">
        <div className="col-6">
          <div className="default-form__input-group">
            <label
              htmlFor="player_first_name"
              className="default-form__label"
            >
              First name <span className="u-color-red">*</span>
            </label>
            <input
              type="text"
              id="player_first_name"
              required="required"
              className={classNames(
                'browser-default default-form__input',
                { 'readonly-field': isDisabled },
              )}
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
            {!!errors.firstName && (
              <div className="input-field__error">
                {errors.firstName}
              </div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="default-form__input-group">
            <label
              htmlFor="player_last_name"
              className="default-form__label"
            >
              Last name <span className="u-color-red">*</span>
            </label>
            <input
              type="text"
              id="player_last_name"
              required="required"
              className={classNames(
                'browser-default default-form__input',
                { 'readonly-field': isDisabled },
              )}
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            {!!errors.lastName && (
              <div className="input-field__error">
                {errors.lastName}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="default-form__input-group">
            <label
              htmlFor="player_phone"
              className="default-form__label"
            >
              Phone <span className="u-color-red">*</span>
            </label>
            <PhoneInput
              id="player_phone"
              name="player_phone"
              required="required"
              defaultCountry="AU"
              placeholder="Insert phone number here"
              value={phone}
              onChange={handleOnChange}
              className={classNames({
                'invalid-number': !isPhoneValid,
                'readonly-field': isDisabled,
              })}
              displayInitialValueAsLocalNumber
              disabled={isDisabled}
              required
            />
            {!!errors.phone && (
              <div className="input-field__error">{errors.phone}</div>
            )}
          </div>
        </div>
      </div>
      <div className="row u-pb-3">
        <div className="col-12">
          <div className="default-form__input-group">
            <label
              htmlFor="player_email"
              className="default-form__label"
            >
              Email{' '}
              <i
                className="fal fa-question-circle"
                data-tip={tooltipMessage}
              />
              <ReactTooltip place="right" className="email_tooltip" />
            </label>
            <input
              type="email"
              id="player_email"
              required="required"
              className="browser-default default-form__input readonly-field"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled
            />
            {!!errors.email && (
              <div className="input-field__error">{errors.email}</div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex u-pb-5">
        <button
          type="submit"
          className="Button Button--primary Button--medium u-mr-1"
          disabled={isDisabled}
        >
          Save Changes
        </button>
        <a href="/players" className="Button Button--medium u-lh-20">
          Cancel
        </a>
      </div>
    </form>
  );
}
