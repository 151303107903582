import React from 'react';
import PropTypes from 'prop-types';

export const LABEL_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

const ToggleCheckbox = ({
  onChange,
  checked,
  label,
  value,
  labelPosition,
  className,
}) => {
  return (
    <label
      className={`Checkbox d-flex align-items-center u-pointer ${className}`}
    >
      {labelPosition === LABEL_POSITION.LEFT && (
        <span className="Checkbox-caption u-pr-1">{label}</span>
      )}
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        className="Checkbox-source"
      />
      <span className="lever Checkbox-result" />
      {labelPosition === LABEL_POSITION.RIGHT && (
        <span className="Checkbox-caption u-weight-500 u-pl-1">
          {label}
        </span>
      )}
    </label>
  );
};

ToggleCheckbox.propTypes = {
  labelPosition: PropTypes.oneOf(Object.values(LABEL_POSITION)),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired, 
    PropTypes.array.isRequired
  ]),
  value: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
};

ToggleCheckbox.defaultProps = {
  labelPosition: LABEL_POSITION.RIGHT,
  className: '',
};

export default ToggleCheckbox;
