const transfers = 'transfers';
const refunds = 'refunds';
const userRefunds = 'userRefunds';
const breakdowns = 'breakdowns';

const tabNames = {
    [transfers]: 'Transfers',
    [refunds]: 'Refunds',
    [userRefunds]: 'User Refunds',
    [breakdowns]: 'Breakdown'
}

export {
    transfers,
    refunds,
    userRefunds,
    breakdowns,
    tabNames
}