import React from 'react';
import Modal from '../shared/Modal';
import { toast } from '../../lib/utils';
import { deleteError } from '../shared/errorMessages';

export default class DeleteFixtureModal extends React.Component {
  hideDeleteModal = () => {
    $('#delete-fixture_modal').hide();
  };

  handleRemoveFixture = () => {
    const { fixtureUrl } = this.props;
    $.ajax({
      url: fixtureUrl,
      type: 'DELETE',
      dataType: 'json',
      success() {
        location.href = '/leagues/';
        this.hideDeleteModal();
      },
      error(response) {
        toast(response.responseJSON.message, 'fail', deleteError)
      }
    });
  };

  renderFooter = () => (
    <div className="d-flex justify-content-center">
      <a
        className="Button Button--redBg u-mr-1"
        onClick={this.handleRemoveFixture}
      >
        Delete
      </a>
      <a
        className="Button Button--cancel"
        onClick={this.hideDeleteModal}
      >
        Cancel
      </a>
    </div>
  );

  render() {
    return (
      <Modal
        id="delete-fixture_modal"
        show
        footer={this.renderFooter()}
      >
        <div className="center-align">
          <h5 className="u-weight-500 u-pb-2">Delete fixture</h5>
          <p className="u-pb-3">
            Are you really want to delete fixture?
          </p>
        </div>
      </Modal>
    );
  }
}
