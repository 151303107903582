import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';

export default class SelectDefault extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    mod: PropTypes.string,
    fieldMod: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    ignore: PropTypes.array,
    value: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
    nullable: PropTypes.bool,
    noInit: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  componentDidMount() {
    $(this.selectBox).on('change', this.onChange.bind(this));
  }

  onChange({ target: { value } }) {
    const { onSelect } = this.props;
    onSelect(value);
  }

  render() {
    const {
      title,
      items,
      fieldMod = '',
      mod = '',
      name,
      id,
      value,
      ignore = [],
      nullable,
      disabled,
    } = this.props;

    return (
      <div ref={(el) => (this.selectBox = el)}>
        <label
          htmlFor={id}
          className={`default-form__label${
            value ? '_filled active' : '_empty'
          }`}
        >
          {title}
        </label>
        <select
          className={`browser-default default-form__input ${mod}`}
          name={name || id}
          id={id}
          onChange={this.onChange.bind(this)}
          value={nullable ? value : value || -1}
          disabled={disabled}
        >
          {items.length > 0 && <option disabled value={-1} />}
          {items.map(
            (item, i) => !ignore.includes(item.id || item) && (
                <option
                  key={i}
                  value={typeof item === 'object' ? item.id : item}
                >
                  {item.title || item}
                </option>
              ),
          )}
        </select>
      </div>
    );
  }
}
