import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import Select from './Select';
import { TZ } from './configs';
import {
  MINUTES_RANGE,
  HOURS_RANGE,
} from '../../lib/timeSlotsHelper';

export default class TimePicker extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    normalize: PropTypes.func,
  };

  static defaultProps = {
    normalize: (v) => moment(v).format(), // v.format('2000-01-01THH:mm') + ':00.000Z'
  };

  updateHours = (hours) => {
    const { onChange, value, normalize } = this.props;
    onChange(
      normalize(moment(value).hours(moment(hours, 'h A').hours())),
    );
  };

  updateMinutes = (minutes) => {
    const { onChange, value, normalize } = this.props;
    onChange(
      normalize(
        moment(value).minutes(moment(minutes, 'm').minutes()),
      ),
    );
  };

  render() {
    const { id, value } = this.props;
    return (
      <div className="time-picker">
        <Select
          mod="select-dropdown_small"
          items={HOURS_RANGE}
          value={moment(value).format('h A')}
          id={`${id}-hours`}
          onSelect={this.updateHours}
          nullable
          noInit
        />
        <Select
          mod="select-dropdown_small"
          items={MINUTES_RANGE}
          value={moment(value).format('m')}
          id={`${id}-minutes`}
          onSelect={this.updateMinutes}
          nullable
          noInit
        />
      </div>
    );
  }
}
