import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import { maxBy, filter } from 'lodash';
import { teamsExportDTO } from '../../lib/DTO';
import { redirect } from '../../lib/utils';
import Spinner from '../shared/Spinner';

export default class TeamsExport extends React.Component {
  state = {
    includedSeasons: this.props.seasons,
    selectedIncluded: [],
    errors: [],
    isLoading: false
  };

  lastPlayedSeasonDate = (selectedOption) => {
    const lastPlayedSeason = maxBy(selectedOption, (season) => Date.parse(season.start_date),
    );

    if (lastPlayedSeason) {
      return Date.parse(lastPlayedSeason.start_date);
    }
    return null;
  };

  handleIncludedChange = (selectedOption) => {
    let seasons = filter(
      this.props.seasons,
      (season) => !selectedOption.includes(season),
    );

    const lastPlayedSeasonDate = this.lastPlayedSeasonDate(
      selectedOption,
    );

    if (lastPlayedSeasonDate) {
      seasons = filter(
        seasons,
        (season) => Date.parse(season.start_date) > lastPlayedSeasonDate,
      );
    }

    this.setState({
      selectedIncluded: selectedOption,
    });
  };

  toggleLoadingStatus = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }))
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { selectedIncluded } = this.state;

    axios
      .post(
        '/settings/teams_export.json',
        teamsExportDTO(selectedIncluded),
        this.toggleLoadingStatus()
      )
      .then((res) => {
        const data = new Blob([res.data], { type: 'text/csv' });
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.download = 'teams_export.csv';
        tempLink.click();
        this.toggleLoadingStatus()
      })
      .catch((error) => {
        M.toast({
          html: error.response.data,
          classes: 'u-bg-emerald',
        });
      });
  };

  render() {
    const { includedSeasons, selectedIncluded, isLoading } = this.state;

    const isSubmittable = selectedIncluded.length === 0;

    return (
      <div className="Card u-mb-3">
        <div className="Card-content u-pt-4 u-pb-5">
          <p className="subtitle">Teams export</p>

          <div className="dotLoader">
            <Spinner isLoading={isLoading} position={{margin: '0 auto'}}/>
          </div>

          <form onSubmit={(e) => this.handleSubmit(e)}>
            <div className="u-pt-1 u-pb-4">
              <div className="input-field u-mb-0">
                <label className="label-wrapper">Included</label>
                <Select
                  className="u-pt-2"
                  isMulti
                  isSubmittablearchable
                  hideSelectedOptions
                  getOptionLabel={(option) => `${option.title}`}
                  getOptionValue={(option) => `${option.id}`}
                  value={selectedIncluded}
                  onChange={this.handleIncludedChange}
                  options={includedSeasons}
                />
              </div>
            </div>

            <div className="u-pb-3">
              <button
                type="submit"
                className={`Button Button--primary ${
                  isSubmittable ? 'Button--disabled' : ''
                }`}
                disabled={isSubmittable}
              >
                Download CSV
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
