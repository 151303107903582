import {addPaymentItem, patchPaymentApi, putPaymentApi} from "./_payments_tabs_api";
import { toast } from "../../../lib/utils";
import {
    invoiceNotFound,
    errorMessage,
    amountAdjustmentStatus,
    invoiceWrongStatus,
    refundFaild,
    payfeeFaild
} from "../../shared/errorMessages";

const voidFeeCallback = (setPaymentsListFunc, disablePopupFunc) => (payment) => {
    patchPaymentApi(`/api/invoices/${payment.id}/void`)
        .then(status => {
            disablePopupFunc();

            switch (status) {
                case 200:
                    setPaymentsListFunc(prevList => prevList.filter(item => item.id !== payment.id));
                    break;
                case 404:
                    window.alert(invoiceNotFound);
                    break;
                default:
                    window.alert(errorMessage);
            }
        })
}

const markAsPaidCallback = (setPaymentsListFunc, disablePopupFunc) => (payment) => {
    putPaymentApi(`/api/invoices/${payment.id}/mark_as_paid`)
        .then(response => {
            disablePopupFunc();

            switch (response.status) {
                case 200:
                    setPaymentsListFunc(payment);
                    break;
                case 403:
                    toast(response.data.message, 'fail', payfeeFaild);
                    break
                case 404:
                    window.alert(invoiceNotFound);
                    break;
                default:
                    window.alert(errorMessage);
            }
        })
}

const refundCallback = (setPaymentList, disablePopup) => (payment, params, setAdjustPaymentStatus) => {
    putPaymentApi(`/api/invoices/${payment.id}/refund?${params}`)
        .then(response => {

            switch (response.status) {
                case 200:
                    changeFeeOkStatusAction(payment, response, setPaymentList)
                    disablePopup();
                    break;
                case 422:
                    toast(response.data.message, 'fail', refundFaild)
                    break;
                default:
                    setAdjustPaymentStatus(invoiceWrongStatus);
            }
        })
}

const adjustFeeCallback = (setPaymentList, disablePopup) => (payment, params, setAdjustPaymentStatus) => {
    putPaymentApi(`/api/invoices/${payment.id}?${params}`)
        .then(response => {

            switch (response.status) {
                case 200:
                    changeFeeOkStatusAction(payment, response, setPaymentList)
                    disablePopup();
                    break;
                case 422:
                    setAdjustPaymentStatus(amountAdjustmentStatus);
                    break;
                default:
                    setAdjustPaymentStatus(invoiceWrongStatus);
            }
        })
}

const addPaymentItemCallback = (teamId, createdCallback, disablePopup) => (bodyFormData) => {
    addPaymentItem(`/teams/${teamId}/payments`, bodyFormData)
        .then(response => {
            switch (response.status) {
                case 201:
                    createdCallback();
                    disablePopup();
                    break;
                default:
                    disablePopup();
                    window.alert(errorMessage);
            }
        })
}

const changeFeeOkStatusAction = (payment, response, setPaymentList) => {
    const updatedPayment = response.data.data;
    setPaymentList(previousList => {
        const index = previousList.findIndex(elem => elem.id === payment.id);
        return [...previousList.slice(0, index), updatedPayment, ...previousList.slice(index + 1)]
    })
}

export {
    voidFeeCallback,
    markAsPaidCallback,
    refundCallback,
    adjustFeeCallback,
    addPaymentItemCallback,
    changeFeeOkStatusAction
}
