import PropTypes from 'prop-types';
import React from 'react';
import TextInput from './TextInput';
import Search from './Search';
import { updateInputs } from '../../lib/materialHelper';

export default class Popup extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    selected: PropTypes.object,
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  state = {
    selected: this.props.selected || { title: '' },
    filter: '',
  };

  componentDidMount() {
    const { id } = this.props;
    const dropdownEl = $(`#${id}_content`);

    dropdownEl.dropdown({
      constrainWidth: true,
      hover: false,
      coverTrigger: false,
      alignment: 'left',
      closeOnClick: true,
    });

    $(`#${id}_content input`).on('click', (e) => {
      e.stopPropagation();
      dropdownEl.dropdown('open');
    });

    updateInputs();
  }

  select(selected) {
    const { onSelect } = this.props;

    this.setState({ selected, filter: '' }, () => {
      updateInputs();
      onSelect(selected);
    });
  }

  render() {
    const { title, items, id } = this.props;
    const { selected, filter } = this.state;

    return (
      <div className="popup input-field input-field_high">
        <div
          id={`${id}_content`}
          className="dropdown-trigger popup"
          data-target={id}
        >
          <TextInput
            id={`${id}_input`}
            title={title}
            value={selected.title}
          />
        </div>
        <ul
          id={id}
          className="popup__wrapper popup__wrapper--new-team"
        >
          <div className="popup__search">
            <Search
              id="popup_search"
              onChange={(filter) => this.setState({ filter })}
            />
          </div>
          {items.map(
            (item, i) => item.element
              || (item.title.toLowerCase().includes(filter) && (
                <li
                  className={item === selected ? 'selected' : null}
                  key={i}
                  onClick={() => this.select(item)}
                >
                  <span>{item.title}</span>
                </li>
              )),
          )}
        </ul>
      </div>
    );
  }
}
