import React from 'react';
import icons from './ratingIconsHelper';
import { SCORE_STATUS } from '../components/ScheduleForm/constants';

export { validateMatchesCompleteness };

function validateMatchesCompleteness(matches = [], isSynced) {
  const errors = new Map();

  matches.map((match) => {
    validateScores(match, errors);
    validatePaids(match, errors);
  });

  const errorMessages = [];
  if (errors.size > 0)
    errors.forEach((err) => errorMessages.push(` - ${err}`));

  return errorMessages;
}

function validateScores(match, errors) {
  if (
    match.score_status !== SCORE_STATUS[3]
    && (match.score_a === null || match.score_b === null)
  ) {
    errors.set('score', 'A result must be recorded for each game');
  }
}

function validatePaids(match, errors) {
  const {
    team_a_balance,
    team_b_balance,
    fee_a,
    fee_b,
    paid_a,
    paid_b,
  } = match;

  const isValidTeamA = isValidPaidForTeam(
    team_a_balance,
    fee_a,
    paid_a,
  );
  const isValidTeamB = isValidPaidForTeam(
    team_b_balance,
    fee_b,
    paid_b,
  );

  if (!isValidTeamA || !isValidTeamB) {
    errors.set(
      'paid',
      'A value must be recorded for each payment field containing fee or other payments',
    );
  }
}

function isValidPaidForTeam(balance, fee, paid) {
  if (
    paid === null
    && (parseFloat(balance) > 0 || parseFloat(fee) > 0)
  )
    return false;

  return true;
}
