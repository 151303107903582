import React, {useRef, useEffect, useState} from 'react'
import {paid, overdues} from './util/_tabs'
import {refund, paymentInformation, adjustFee, voidFee, markAsPaid} from './util/_options'

export default function DropdownList({tab, onOptionClick, status}) {

    const ref = useRef(null);
    const [dropDirectionClassname, setDropDirectionClassname] = useState('dropdown-list_invisible');
    const [dropLocationStyle, setDropLocationStyle] = useState({});

    useEffect(() => {
        setDropDirectionClassname('');
        const element = ref.current.getBoundingClientRect();
        setDropLocationStyle(element.bottom > window.innerHeight ?
            {top: `-${element.height}px`} : {top: '-10px'})
    }, [])

    const listContent = [];
    switch (tab) {
        case paid:
            if (status === 'paid') {
                listContent.push(refund)
            }
            listContent.push(paymentInformation);
            break;
        case overdues:
            listContent.push(refund, paymentInformation);
            break;
        default:
            listContent.push(refund, paymentInformation);
    }

    return (
        <div className="u-relative">
            <ul ref={ref}
                className={`DropdownList ${dropDirectionClassname}`}
                style={dropLocationStyle}
            >
                <li className="DropdownList-row"
                    key="Options"
                >
                    Options
                </li>
                {listContent.map(item => {
                    return (
                        <li className="DropdownList-row DropdownList-row--content"
                            key={item}
                            onClick={() => onOptionClick(item)}
                        >
                            {item}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
