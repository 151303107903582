import React, { useState } from 'react';
import axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { redirect, handleResponseErrorFormat } from '../../lib/utils';
import Form from './Form';

export default function CreateTeam() {
  const [errors, setErrors] = useState({});

  const handleSubmit = (
    existingPlayerId,
    title,
    firstName,
    lastName,
    email,
    phone,
  ) => {
    let isNeedToAddPhone = false;
    const teamData = {
      team: {
        title,
      },
    };

    if (existingPlayerId) {
      teamData.team.user_id = existingPlayerId;
      teamData.team.phone = phone;
      isNeedToAddPhone = isValidPhoneNumber(phone);
    } else {
      teamData.team.user_attributes = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
      };
    }

    isNeedToAddPhone ? addTeamWithPhone(teamData) : addTeam(teamData);
  };

  const addTeamWithPhone = (data) => axios
      .put(`/api/players/${data.team.user_id}`, {
        phone: data.team.phone,
      })
      .then(() => {
        axios.post('/api/teams.json', data).then(() => {
          M.toast({
            html: 'Team successfully created',
            classes: 'u-bg-emerald',
          });
          redirect('/teams');
        });
      })
      .catch((error) => {
        setErrors(handleResponseErrorFormat(error));
      });

  const addTeam = (data) => axios
      .post('/api/teams.json', data)
      .then(() => {
        M.toast({
          html: 'Team successfully created',
          classes: 'u-bg-emerald',
        });
        redirect('/teams');
      })
      .catch((error) => {
        setErrors(handleResponseErrorFormat(error));
      });

  const formOptions = {
    emailLabel: 'Captain email',
    createButtonLabel: 'Create team',
    cancelButtonPath: '/teams',
    userFoundMessage: 'User found',
  };

  return (
    <React.Fragment>
      <div className="Card">
        <div className="Card-content">
          <Form
            isCreatingTeam
            formOptions={formOptions}
            errors={errors}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
