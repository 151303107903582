export function getMatchUrl(leagueId, matchId) {
  return `/leagues/${leagueId}/matches/${matchId}`;
}

export function getFixtureUrl(leagueId) {
  return `/leagues/${leagueId}/fixture`;
}

export function getFinalFixtureUrl(leagueId) {
  return `/leagues/${leagueId}/final_fixture`;
}

export function getRoundUrl(leagueId, roundId) {
  return `/leagues/${leagueId}/rounds/${roundId}`;
}

export function getLeagueUrl(leagueId) {
  return `/leagues/${leagueId}`;
}
