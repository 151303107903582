import PropTypes from 'prop-types';
import React from 'react';

export default class Checkbox extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    classes: PropTypes.string,
  };

  static defaultProps = {
    checked: false,
    disabled: false,
  };

  state = {
    checked: this.props.checked,
  };

  _handleCheckboxChange = (e) => {
    this.setState({ checked: e.target.checked });
    this.props.onChange(e);
  };

  render() {
    const { value, labelText, disabled, classes, id } = this.props;
    const { checked } = this.state;

    return (
      <label>
        <input
          id={id}
          className={classes}
          defaultChecked={checked}
          type="checkbox"
          value={value}
          disabled={disabled}
          onChange={this._handleCheckboxChange}
        />
        <span>{labelText}</span>
      </label>
    );
  }
}
