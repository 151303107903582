import React from 'react';
import PropTypes from 'prop-types';

const InputControl = ({ id, label, value, onChange, error }) => (
  <div className="default-form__input-group">
    <label htmlFor={id} className="default-form__label">
      {label} <span className="u-color-red">*</span>
    </label>
    <input
      type="text"
      id={id}
      required
      className="browser-default default-form__input"
      onChange={(e) => onChange(e.target.value)}
      value={value}
    />
    {!!error && <div className="input-field__error">{error}</div>}
  </div>
);

export default InputControl;
