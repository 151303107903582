import React from 'react';
import Modal from '../shared/Modal';
import Button from '../shared/Button';

export const TimeslotPreview = ({ active, onClose, teams, data }) => data ? (
    <Modal
      show={active}
      id="timeslot-preview-modal"
      className="edit-fixture__modal"
    >
      <table className="edit-fixture u-w-100">
        <tbody>
          <tr className="edit-fixture__match-row">
            <td className="edit-fixture__cell edit-fixture__cell_subtitle edit-fixture__cell_title">
              team
            </td>
            {data.times.map((time) => (
              <td
                className="edit-fixture__cell edit-fixture__cell_subtitle edit-fixture__cell_count"
                key={time}
              >
                {time}
              </td>
            ))}
          </tr>
          {Object.keys(data.table)
            .sort()
            .map((team) => (
              <tr className="edit-fixture__match-row" key={team}>
                <td className="edit-fixture__cell edit-fixture__cell_title">
                  {team}
                </td>
                {data.times.map((time) => (
                  <td
                    className="edit-fixture__cell edit-fixture__cell_count"
                    key={time + team}
                  >
                    {data.table[team][time] || 0}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <Button
        title="Close"
        id="timeslot-preview-close-btn"
        onClick={onClose}
      />
    </Modal>
  ) : null;
