import React from "react";
import PropTypes from "prop-types"
import {moneyFormatterZeros} from "../../lib/utils"
import Spinner from "../shared/Spinner";

export default function Balance({availableBalance, pendingBalance, isLoadingBalance}) {



    return (
        <div className="Balance-block">
            <div className="Balance">
                <span className="AvailableBalance-title">Available Balance</span>
                <div className="AvailableBalance-block">
                        <Spinner isLoading={isLoadingBalance}
                                position={{right: '70%', bottom: '25%'}}/> 
                        {!isLoadingBalance ? 
                             <span className="AvailableBalance-amount">${moneyFormatterZeros(availableBalance)}</span>
                        : null}
                </div>
            </div>
            <div className="Balance">
                <span className="AvailableBalance-title">Pending Balance</span>
                <div className="AvailableBalance-block">
                        <Spinner isLoading={isLoadingBalance}
                                position={{right: '20%', bottom: '25%'}}/> 
                        {!isLoadingBalance ? 
                            <span className="AvailableBalance-amount">${moneyFormatterZeros(pendingBalance)}</span>
                        : null}
                </div>
            </div>
        </div>
    ) 

}

Balance.propTypes = {
    availableBalance: PropTypes.oneOfType([
        PropTypes.string, 
        PropTypes.number]).isRequired,
    pendingBalance: PropTypes.oneOfType([
        PropTypes.string, 
        PropTypes.number]).isRequired,
    isLoadingBalance: PropTypes.bool.isRequired
}
