import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';

const AttendanceIcon = ({ loading, onClick, attendance }) => {
  const attendanceIcon = (
    <i className="fal fa-users u-font-size-16 u-weight-500 u-color-silver-chalice" />
  );
  const submittedIcon = (
    <i className="Icon Icon--submitted">{attendance}</i>
  );

  const renderIcon = () => {
    return attendance !== 0 ? submittedIcon : attendanceIcon;
  };

  if (loading) {
    return (
      <div className="u-relative">
        <Spinner isLoading={loading} />
      </div>
    );
  }

  return (
    <a onClick={onClick} className="table__action" href="#">
      {renderIcon()}
    </a>
  );
};

AttendanceIcon.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  attendance: PropTypes.number.isRequired,
};

export default AttendanceIcon;
