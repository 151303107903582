import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class ModalClosableOutside extends React.Component {
  static propTypes = {
    onHideModal: PropTypes.func,
    isModalWindowMode: PropTypes.bool,
    isAllowEmerge: PropTypes.bool,
    cssId: PropTypes.string,
  };

  static defaultProps = {
    isModalWindowMode: true,
    isAllowEmerge: false,
  };

  modalRef = React.createRef();

  componentDidMount() {
    document.addEventListener(
      'mousedown',
      this.handleOutsideClick,
      false,
    );
    document.addEventListener('keydown', this.handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleOutsideClick,
      false,
    );
    document.removeEventListener('keydown', this.handleEscKey, false);
  }

  handleOutsideClick = (e) => {
    if (this.modalRef) {
      const domNode = ReactDOM.findDOMNode(this.modalRef);

      if (domNode == e.target || !domNode.contains(e.target)) {
        this.props.onHideModal();
      }
    }
  };

  handleEscKey = (e) => {
    if (e.keyCode === 27) {
      this.props.onHideModal();
    }
  };

  render() {
    const {
      children,
      isModalWindowMode,
      isAllowEmerge,
      cssId,
    } = this.props;
    const restProps = cssId ? { id: cssId } : {};

    if (isModalWindowMode) {
      return (
        <div
          className="modal-window"
          ref={(node) => (this.modalRef = node)}
          {...restProps}
        >
          <div
            className={`modal-window__content ${
              isAllowEmerge ? 'modal-window__content--emerge' : ''
            }`}
          >
            {children}
          </div>
        </div>
      );
    }
    return (
      <span ref={(node) => (this.modalRef = node)}>{children}</span>
    );
  }
}
