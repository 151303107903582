import PropTypes from 'prop-types';
import React from 'react';

export default class Button extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    mod: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      title,
      id,
      onClick,
      type = 'button',
      disabled = false,
      mod = '',
    } = this.props;

    return (
      <button
        className={`Button ${mod} ${
          disabled ? 'Button--disabled' : ''
        }`}
        id={id}
        onClick={(e) => !disabled && onClick(e)}
        type={type}
        disabled={disabled}
      >
        {title}
      </button>
    );
  }
}
