const paymentInformation = 'View payment information';
const refund = 'Refund';
const voidFee = 'Void Fee';
const markAsPaid = 'Mark as Paid';
const adjustFee = 'Adjust Fee';
const addPaymentItem = 'Add payment item';
const editPayment = 'Edit Payment';
const editFee = 'Edit Fee';

export {
    paymentInformation,
    refund,
    voidFee,
    markAsPaid,
    adjustFee,
    addPaymentItem,
    editFee,
    editPayment
}
