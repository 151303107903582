import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { SVG } from './SVG';
import Calendar from './Calendar';

const FORMAT = 'MMMM DD';

export default class DateInput extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.object,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    format: PropTypes.string,
    mod: PropTypes.string,
    onChange: PropTypes.func,
    measure: PropTypes.string,
    error: PropTypes.array,
  };

  state = {
    active: false,
    value: this.props.value,
  };

  UNSAFE_componentWillReceiveProps({ error }) {
    if (this.props.error !== error) {
      this.touched = false;
    }
  }

  updateValue(value) {
    const { onChange = () => {} } = this.props;
    this.setState({
      value,
      active: false,
    });
    this.touched = true;
    onChange(value);
  }

  render() {
    const {
      placeholder,
      title,
      id,
      error,
      mod,
      format = FORMAT,
    } = this.props;

    const { active, value } = this.state;
    const className = classnames({
      'input-field': true,
      'pseudo-element': true,
      'input-field_high': title,
      'input-field_invalid': !this.isValid,
    });

    return (
      <div
        className={className}
        onClick={() => this.setState({ active: !active })}
      >
        <input
          className={`text-input__input ${mod}`}
          placeholder={placeholder}
          readOnly
          id={id}
          value={value ? moment(value).format(format) : ''}
        />
        <label
          htmlFor={id}
          className={`input-field__label${value ? ' active' : ''}`}
        >
          {title}
        </label>
        {!!error && !this.touched && (
          <div className="input-field__error">{error.join(', ')}</div>
        )}
        {active && (
          <div className="popup__content">
            <Calendar
              onSelect={(value) => this.updateValue(value)}
              value={value}
            />
          </div>
        )}
      </div>
    );
  }
}
