import React from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'react-phone-number-input';
import { localFormatPhoneNumber } from '../../helpers/phoneHelper';

export default function PhoneNumberFormat({ phoneNumber }) {
  return <div>{localFormatPhoneNumber(phoneNumber)}</div>;
}

PhoneNumberFormat.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};
