import React from 'react';
import PropTypes from 'prop-types';
import {
  updateInputs,
  updateSelects,
} from '../../lib/materialHelper';

export default class Modal extends React.Component {
  static propTypes = {
    footer: PropTypes.node,
    show: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
  };

  UNSAFE_componentWillReceiveProps({ show }) {
    if (show) {
      $('body').css('overflow', 'hidden');
      setTimeout(() => {
        updateInputs();
        updateSelects();
      }, 100);
    } else {
      $('body').css('overflow', 'inherit');
    }
  }

  componentDidUpdate() {
    if (!this.props.show) {
      this.props.onClose && this.props.onClose();
    }
  }

  componentWillUnmount() {
    $('body').css('overflow', 'inherit');
  }

  render() {
    const { id, footer, show, children } = this.props;

    return show ? (
      <div id={id} className="modal-window">
        <div className="modal-window__content">
          {children}
          <div className="modal-window__footer">{footer}</div>
        </div>
      </div>
    ) : null;
  }
}
