import PropTypes from 'prop-types';
import React from 'react';

export default function Spinner({ isLoading, position }) {
  return isLoading ? (
    <span className="u-absolute" style={{ ...position }}>
      <i className="fa fa-spinner fa-spin fa-2x fa-fw" />
    </span>
  ) : null;
}

Spinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

Spinner.defaultProps = {
  position: {
    top: 0,
    right: 0,
  },
};
