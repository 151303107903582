import React, { useState } from 'react';
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input';
import classnames from 'classnames';

export default function PlayerPhoneInput({
  phoneNumber,
  errorMessage,
  inputId,
  inputName,
  className,
}) {
  const [phone, setPhone] = useState(
    phoneNumber ? `+${phoneNumber}` : '',
  );
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState(errorMessage || '');

  const submitButton = document.querySelector('input[type="submit"]');

  const addPhoneDataToForm = (value) => {
    document.getElementById(inputId).value = value;
  };

  const handleSubmitButton = (isPhoneValid) => {
    submitButton.disabled = !isPhoneValid;
    return isPhoneValid;
  };

  const handleOnChange = (value) => {
    const isNumberValid = isValidPhoneNumber(value);
    setErrorMsg('');

    setPhone(value);
    addPhoneDataToForm(value);
    setIsPhoneValid(isNumberValid);
    handleSubmitButton(isNumberValid);
  };

  return (
    <React.Fragment>
      <input
        type="hidden"
        id={inputId}
        name={inputName}
        value={phone}
      />
      <PhoneInput
        id="phone_input"
        name="phone_input"
        defaultCountry="AU"
        required="required"
        placeholder="Mobile number"
        value={phone}
        onChange={handleOnChange}
        className={classnames(className, {
          'invalid-number': !isPhoneValid,
        })}
        countrySelectProps={{ tabIndex: '3' }}
        numberInputProps={{ tabIndex: '4' }}
        displayInitialValueAsLocalNumber
        required
      />
    </React.Fragment>
  );
}
