import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

export default class SlotsCount extends React.Component {
  static propTypes = {
    required: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
  };

  render() {
    const { required, current } = this.props;

    return (
      <div>
        <CircularProgressbar
          className="CircularProgressbar_small"
          textForPercentage={() => ''}
          percentage={(current / required) * 100}
          strokeWidth={16}
        />
        <span className="timeslots__required">
          {current} /{required} required
        </span>
      </div>
    );
  }
}
