import React from 'react';
import Button from '../shared/Button';

export const FixtureActions = ({
  position,
  onSave,
  onDelete,
  onPreview,
  disabled,
  hideSave,
}) => (
  <div className="d-flex justify-content-between u-pb-2 u-w-100">
    <Button
      mod="Button--primary"
      title="Save Changes"
      id={`save-sync-${position}`}
      onClick={() => onSave(true)}
      disabled={disabled}
    />
    {position === 'top' && onDelete && (
      <Button
        mod="Button Button--redBg edit-fixture__delete-button"
        title="Delete finals"
        id={`delete-${position}`}
        onClick={onDelete}
      />
    )}
    {onPreview && (
      <Button
        title="Timeslot preview"
        id="timeslot-preview-btn"
        onClick={onPreview}
        mod="edit-fixture__preview-button"
      />
    )}
  </div>
);
