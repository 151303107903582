import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class Switch extends React.Component {
  static propTypes = {
    values: PropTypes.array.isRequired,
    value: PropTypes.string,
    id: PropTypes.string.isRequired,
    mod: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    disabledButtons: PropTypes.arrayOf(PropTypes.string),
  };

  render() {
    const {
      value,
      values,
      id,
      onSelect,
      disabledButtons = [],
      mod = '',
    } = this.props;

    const btnPossition = (index) => {
      const items = {
        0: 'Button--switchFirst',
        1: 'Button--switchMiddle',
        2: 'Button--switchLast',
      };

      return items[index];
    };

    const btnClass = (item, index) => {
      return classnames(
        'Button Button--medium',
        btnPossition(index),
        {
          'Button--primary': item === value,
          'Button--disabled Button--inactive': isDisabled(item),
        },
      );
    };

    const isDisabled = (item) => {
      return disabledButtons.find((i) => i === item);
    };

    return (
      <div className={`switch ${mod} d-flex`} id={id}>
        {values.map((item, i) => (
          <span
            className={btnClass(item, i)}
            key={i}
            onClick={() => onSelect(item)}
          >
            {item}
          </span>
        ))}
      </div>
    );
  }
}
