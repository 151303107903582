import React, { useState } from 'react';
import axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { playerObjectDTO } from '../../lib/DTO';
import { redirect, handleResponseErrorFormat } from '../../lib/utils';
import Form from './Form';

export default function CreatePlayer() {
  const [errors, setErrors] = useState({});

  const handleSubmit = (
    existingPlayerId,
    title,
    firstName,
    lastName,
    email,
    phone,
  ) => {
    const playerData = playerObjectDTO(
      firstName,
      lastName,
      email,
      phone,
    );
    const isNeedToAddPhone =      existingPlayerId && isValidPhoneNumber(phone);

    if (existingPlayerId) {
      isNeedToAddPhone
        ? addPlayerRoleWithPhone(existingPlayerId, phone)
        : addPlayerRole(existingPlayerId);
    } else {
      addPlayer(playerData);
    }
  };

  const addPlayerRoleWithPhone = (playerId, phone) => axios
      .put(`/api/players/${playerId}`, { phone })
      .then(() => {
        axios.put(`/api/roles/players/${playerId}`).then(() => {
          M.toast({
            html: 'Player role successfully added',
            classes: 'u-bg-emerald',
          });
          redirect('/players');
        });
      })
      .catch((error) => {
        setErrors(handleResponseErrorFormat(error));
      });

  const addPlayerRole = (playerId) => axios
      .put(`/api/roles/players/${playerId}`)
      .then(() => {
        M.toast({
          html: 'Player role successfully added',
          classes: 'u-bg-emerald',
        });
        redirect('/players');
      })
      .catch((error) => {
        setErrors(handleResponseErrorFormat(error));
      });

  const addPlayer = (data) => axios
      .post('/api/players.json', data)
      .then(() => {
        M.toast({
          html: 'Player successfully added',
          classes: 'u-bg-emerald',
        });
        redirect('/players');
      })
      .catch((error) => {
        setErrors(handleResponseErrorFormat(error));
      });

  const formOptions = {
    emailLabel: null,
    createButtonLabel: 'Create player',
    cancelButtonPath: '/players',
    userFoundMessage: 'Player already exists on your account',
  };

  return (
    <React.Fragment>
      <div className="Card">
        <div className="Card-content">
          <h3 className="u-pt-3 u-pb-1 u-weight-500">
            Let's start with their email...
          </h3>
          <Form
            isCreatingTeam={false}
            formOptions={formOptions}
            errors={errors}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
