import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function Alert({ message }) {
  return (
    <React.Fragment>
      <div className="player-error-message">{message}</div>
    </React.Fragment>
  );
}

Alert.propTypes = {
  message: PropTypes.string,
};
