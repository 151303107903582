export const SCORE_STATUS = {
  0: 'clear',
  1: 'team_a_forfeited',
  2: 'team_b_forfeited',
  3: 'omit',
  isStatusSet: (value) => [SCORE_STATUS[1], SCORE_STATUS[2], SCORE_STATUS[3]].includes(
      value,
    ),
  isTeamAForfeited: (value) => value === SCORE_STATUS[1],
  isTeamBForfeited: (value) => value === SCORE_STATUS[2],
  isOmited: (value) => value === SCORE_STATUS[3],
};
