const adjustFeeAmount = 'adjustFeeAmount';
const markFeeAsPaid = 'markFeeAsPaid';
const voidFee = 'voidFee';
const adjustPaymentAmount = 'adjustPaymentAmount';
const issueRefund = 'issueRefund';
const adjustRefundAmount = 'adjustRefundAmount';
const adjustInvoiceDate = 'adjustInvoiceDate';

const allActions = [
    adjustFeeAmount,
    markFeeAsPaid,
    voidFee,
    adjustPaymentAmount,
    issueRefund,
    adjustRefundAmount,
    adjustInvoiceDate
];

const changeFeeActions = [
    adjustFeeAmount,
    issueRefund,
    adjustPaymentAmount,
    adjustRefundAmount
];

const actions = {
    [adjustFeeAmount]: 'Adjust fee',
    [markFeeAsPaid]: 'Mark fee as paid',
    [voidFee]: 'Void fee',
    [adjustPaymentAmount]: 'Adjust payment amount',
    [issueRefund]: 'Issue refund',
    [adjustRefundAmount]: 'Adjust refund amount',
    [adjustInvoiceDate]: 'Adjust fee or due date'
};

const buttonNames = {
    [adjustFeeAmount]: 'Adjust fee',
    [markFeeAsPaid]: 'Mark as paid',
    [voidFee]: 'Void fee',
    [adjustPaymentAmount]: 'Adjust payment',
    [issueRefund]: 'Refund',
    [adjustRefundAmount]: 'Adjust refund'
}

const adjustPaymentPhrases = {
    [adjustFeeAmount]: 'Adjust fee owing to:',
    [adjustPaymentAmount]: 'Adjust amount received to:',
    [issueRefund]: 'Refund amount:',
    [adjustRefundAmount]: 'Adjust refund processed to:'
}

export {
    allActions,
    adjustFeeAmount,
    markFeeAsPaid,
    voidFee,
    adjustPaymentAmount,
    issueRefund,
    adjustRefundAmount,
    actions,
    buttonNames,
    changeFeeActions,
    adjustPaymentPhrases,
    adjustInvoiceDate
}
