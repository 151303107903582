import React, { useState } from 'react';
import axios from 'axios';
import { playerObjectDTO } from '../../lib/DTO';
import { redirect, handleResponseErrorFormat } from '../../lib/utils';
import Form from './Form';
import Alert from './Alert';

export default function PlayerForm({
  player,
  tooltipMessage,
  alertMessage,
  isEditingAllowed,
}) {
  const [playerId, setPlayerId] = useState(player.id);
  const [errors, setErrors] = useState({});
  const isDisabled = !!errors.base || !isEditingAllowed;
  const isShowAlert = errors.base || !isEditingAllowed;

  const handleSubmit = (firstName, lastName, email, phone) => axios
      .put(
        `/api/players/${playerId}.json`,
        playerObjectDTO(firstName, lastName, email, phone),
      )
      .then(() => {
        M.toast({
          html: 'Player successfully updated',
          classes: 'u-bg-emerald',
        });
        redirect('/players');
      })
      .catch((error) => {
        setErrors(handleResponseErrorFormat(error));
      });

  return (
    <React.Fragment>
      <div className="Card">
        <div className="Card-content">
          <h3 className="u-pt-4 u-pb-3 u-weight-500">
            Player details
          </h3>
          <Form
            player={player}
            errors={errors}
            onSubmit={handleSubmit}
            isDisabled={isDisabled}
            tooltipMessage={tooltipMessage}
          />
        </div>
      </div>
      {isShowAlert && <Alert message={alertMessage} />}
    </React.Fragment>
  );
}
