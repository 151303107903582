import moment from 'moment-timezone';
import { TZ } from '../components/shared/configs';

const DATE_FORMAT = 'D MMMM YYYY';
const DATE_FORMAT_D = 'D';
const DATE_FORMAT_M = 'MM';

export const dateRange = (dates) => {
  let dRange = '';
  return dates
    .map((d) => d.format())
    .sort()
    .reduce((res, d, i, arr) => {
      const month = moment(d).format(DATE_FORMAT_M);
      const nextMonth = moment(arr[i + 1]).format(DATE_FORMAT_M);

      if (month === nextMonth && arr[i + 1] !== undefined) {
        dRange += `${moment(d).format(DATE_FORMAT_D)}, `;
        return res;
      }

      if (month !== nextMonth && arr[i + 1] !== undefined) {
        dRange += moment(d).format(DATE_FORMAT);
        res.push(dRange);
        dRange = '';
        return res;
      }

      dRange += moment(d).format(DATE_FORMAT);
      res.push(dRange);
      return res;
    }, []);
};

export const matchDateTime = ({ start_date, start_time }) => {
  const str = start_time
    ? moment(start_time).format()
    : '2000-01-01T23:59:00.000+11:00';
  return str.replace(/^([^T]+)/g, start_date);
};

export const sortMatchesByDateTime = (a, b) => moment(matchDateTime(a)).diff(moment(matchDateTime(b)));
